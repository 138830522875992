import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import GhLogo from "./images/gh-logo.png";
import LILogo from "./images/linkedin-logo.svg";
import logo from './images/logo.svg';
import Casey from "./images/casey-face.png";
import DownArrow from "./images/down-arrow.svg";
import NavBar from "./components/navbar";
import About from "./views/about";
import Work from "./views/work";
import Design from "./views/design";
import Contact from "./views/contact";
import Footer from "./components/footer";
import Lines from "./images/lines.svg";

import './css/app.css';

function App() {
  return (
    <div>
      <img className="lines-art-right" src={Lines}/>
      <NavBar/>
      <div className="header-section align-items-center d-flex flex-md-row flex-column-reverse justify-content-center mt-5 pt-5 container mb-5">
        <div className="me-4">
          <img src={Casey} className="main-headshot flex-1"/>
          <h1>Casey Alvarez</h1>
          <h3 className="text-dark">software engineer</h3>
          <div className="social-logos">
            <img onClick={() => window.location = "https://github.com/CaseyAlvarez"} src={GhLogo}/>
            <img onClick={() => window.location = "https://www.linkedin.com/in/casey-alvarez-28a92123/"} src={LILogo}/>
          </div>
          <p className="my-4 text-start">Passionate software engineer with a specific expertise for creating intuitive and engaging UX/UI. My strong attention to detail and collaborative spirit enable me to turn design concepts into reality efficiently and effectively, delivering seamless and dynamic user interactions. I thrive in team environments, consistently bringing creativity and energy to every project.
          </p>
        </div>
      </div>
      <Link  to="about" delay={0} smooth={false} offset={0}  duration={500}>
        <div className="text-center">
          <p className="pointer text-center mb-0">scroll</p>
          <img className="pointer scroll-arrow"src={DownArrow}/>
        </div>
      </Link>
      <About/>
      {process.env.NODE_ENV === "development" ? (
        <>
          <Work/>
          <div className="divider mt-5"></div>
        </>
      ): null}
      
      <Design/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
