import React, { useState, useRef } from "react";
import CloseIcon from "../images/close-icon-white.svg";
import Stitch from "../images/stitch-logo.svg";
import Qlik from "../images/qlik-logo.svg";
import Talend from "../images/talend-logo.svg";
import { experiences } from "../data";

const JobModal = ({
  job,
  callback
}) => {
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current &&
        !modalRef.current.contains(event.target)
       ) {
      callback(null)
      document.getElementById("html").classList.remove("locked");
    }
  }
  document.addEventListener("mousedown", handleClickOutside);
  
  if (job !== null) {
    const { jobTitle, company, date, description, jobs } = experiences[job]
    const close = () => {
      callback(null)
      document.getElementById("html").classList.remove("locked");
    }
    const companyLogos = {
      "Stitch": Stitch,
      "Talend": Talend,
      "Qlik": Qlik,
    }
    return (
      <div className="job-modal-container">
        <div ref={modalRef} className="job-modal">
          <div className="bg-dark-text d-flex justify-content-end position-fixed w-100 py-4 pe-4">
            <div className="d-flex align-items-center pointer" onClick={close}>
              <div className="me-2 pointer">close</div>
              <img className="close-icon pointer" src={CloseIcon}/>
            </div>
          </div>
          <div className="job-modal-info m-auto">
            <div className="d-flex justify-content-between">
              <div>
                <h2 className="flex-5">{jobTitle}</h2>
                <div className="mt-3 fst-italic text-white-50">
                  <h4 className="fw-bold mb-1">{company}</h4>
                  <h6 className="fst-italic">{date}</h6>
                </div>
              </div>
              {company && <img className="job-logo" src={companyLogos[company]}/>}
            </div>
            <div className="divider my-4"/>
            <p className="mt-5">{description}</p>
            <p className="mt-4">
              <ul>{jobs.map(j => <li>{j}</li>)}</ul>
            </p>
          </div>
          <div className="fade-div"></div>
        </div>
      </div>
    )
  }
  return null
}

export default JobModal;
