export const experiences = [
  {
    jobTitle: "Senior Software Engineer",
    company: "Qlik",
    date: "April 2022 - Current",
    skills: ["React.js", "clojure", "python", "HTML", "SQL", "SCSS/SASS"],
    description: "I am part of a talented team responsible for building a stable and scalable SPA using JS/React, SQL, Clojure, etc.  I not only contribute to the development process but also am responsible for mentoring and onboarding new team members, helping them find their footing in our projects. Collaboration was key, as I worked closely with design and product teams, ensuring that our application not only functioned flawlessly but also provided an exceptional user experience. I took charge of maintaining databases, making sure that data was stored efficiently and securely. Additionally, I played a pivotal role in fostering strong partnerships between the engineering, product, and design departments, facilitating open communication and encouraging positive collaboration.",
    jobs: [
"Tech lead on large and complex full-stack projects, responsible for managing expectations for the product team and assisting/mentoring junior employees",
"Led software development initiative as subject matter expert and primary point-of-contact for project management staff",
"Orchestrated efficient large-scale software deployments. Designed, built, and maintained MySQL/Postgres databases",
"Worked closely with design and product teams to ensure accurate rendering of App views and components",
"Revised, modularized, and updated old bases to modern development standards, reducing operating costs a,nd improving functionality",
"Built a maintainable, and scalable SPA with HTML, SCSS, Javascript/React, and Clojure",
"Prepared and submitted reports and other documentation to assist development team members"
    ]
  },
  {
    jobTitle: "Software Engineer",
    company: "Talend",
    date: "Sept. 2019 - Dec. 2022",
    skills: ["React.js", "clojure", "python", "HTML", "SQL", "SCSS/SASS"],
    description:"During my time as a junior software engineer, I gained valuable experience and made significant contributions to various software development projects. I thrived on problem-solving, always eager to learn and deliver high quality code. Collaborating closely with many different teams, I actively participated in the entire software development lifecycle. From requirements analysis to solution design and implementation, I ensured that our software applications were robust and reliable, following industry best practices and coding standards. I took every opportunity for professional growth, constantly seeking to enhance my skills through online courses and workshops. Not only did I strive to improve my own abilities, but I also actively contributed to enhancing team productivity. With effective communication skills, both written and verbal, I fostered smooth collaboration and efficient problem-solving within the team. As a junior software engineer, I built a solid foundation in software development principles, programming languages, and industry-standard methodologies, which has prepared me for future challenges in the field.",
    jobs: [
      "Developed and maintained software applications using React.js, HTML, and CSS, ensuring adherence to coding standards and best practices.",
      "Collaborated with cross-functional teams to gather requirements, design solutions, and implement software features effectively.",
      "Engaged in the entire software development lifecycle, encompassing requirements analysis, design, coding, testing, and deployment.",
      "Troubleshot and debugged software issues proficiently, employing systematic approaches to identify root causes and implement robust solutions.",
      "Assisted in designing and implementing scalable, high-performance software architecture, considering long-term viability and future growth.",
      "Conducted thorough code reviews, providing constructive feedback to team members and fostering a culture of quality and collaboration.",
      "Applied rigorous testing methodologies, including unit tests and integration testing, to ensure software functionality, reliability, and user satisfaction."
    ]
  },
  {
    jobTitle: "Front End Developer",
    company: "Stitch",
    date: "April 2019 - Sept. 2019",
    skills: ["CSS/SCSS/SASS", "javascript", "Ruby on Rails", "HTML", "Contentful", "photoshop/illustrator", ],
    description: "As the sole developer for the Marketing Site as well as CSS Contractor, I specialized in creating visually captivating and user-friendly websites that enhanced brand presence and engaged audiences. I developed responsive CSS stylesheets, ensuring seamless and consistent website experiences across various devices and screen sizes. With a keen eye for detail, I optimized website performance by implementing industry best practices and conducted rigorous quality assurance testing.",
    jobs: [
      "Created visually captivating and user-friendly marketing websites by applying expert knowledge of CSS and web design principles, ensuring optimal user experience and engagement",
      "Collaborated closely with marketing teams and stakeholders to understand project requirements, translating them into impactful website designs that align with brand guidelines and objectives",
      "Developed and implemented responsive CSS stylesheets, delivering seamless and consistent website experiences across various devices and screen sizes",
      "Leveraged industry best practices and trends to optimize website performance, including loading speed, accessibility, and search engine optimization (SEO) considerations",
      "Conducted thorough quality assurance testing to identify and address any CSS-related issues, ensuring pixel-perfect rendering across different browsers and platforms",
      "Demonstrated a strong attention to detail in crafting pixel-perfect layouts, typography, and visual elements, creating visually stunning and professional websites that elevate brand image",
      "Proactively collaborated with cross-functional teams, including developers and designers, to ensure seamless integration of CSS styles and achieve a cohesive and polished end product",
    ]
  },
  {
    jobTitle: "Web developer, freelance",
    company: null,
    date: "June 2016 - March 2021",
    skills: ["wordpress", "javascript", "HTML", "PHP", "SCSS/SASS"],
    description: "I successfully delivered high-quality and customized web solutions for clients across diverse industries. Proficient in understanding client requirements, designing and developing responsive websites, and implementing intuitive user interfaces. Using my expertise in front-end techonology, I created functional and visually appealing websites that align with client objectives. With excellent communication and project management skills, I collaborated closely with clients to provide technical guidance, troubleshoot issues, and ensure timely project delivery.",
    jobs:[
      "Established strong client relationships, actively communicating and collaborating to understand project requirements, goals, and expectations",
      "Translated client requirements into scalable and user-friendly web solutions, delivering high-quality websites that align with client objectives",
      "Provided personalized consultations and recommendations to clients, guiding them through the web development process and offering expert advice",
      "Maintained regular and proactive communication with clients, providing timely project updates, addressing queries, and managing expectations",
      "Ensured client satisfaction by delivering projects on time and within budget, consistently exceeding expectations and garnering positive feedback",
      "Offered ongoing technical support and maintenance services, promptly addressing client concerns and resolving any issues that arise",
      "Demonstrated strong problem-solving skills, effectively managing client feedback, requests for changes, and scope creep, while maintaining project timelines and quality standards",
    ]
  }
  
]

export const skills =
  ["javascript", "clojure", "python", "SASS/SCSS/CSS", "Git", "React.js", "Redux", "Docker", "Kubernetes", "typescript", "MySQL", "AWS", "PostgreSQL", "Node.js", "Photoshop/Illustrator", "Enzyme/Jest", "Ruby on Rails"];
