import react from 'react';
import Logo from "../images/logo.svg"
import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll'
import pdf from "../images/ca-resume.pdf";

const NavBar = () => {
  return (
    <div className="py-3">
      <div className="nav d-flex justify-content-center">
        <div className="d-flex justify-content-between">
          <Link className="button button-ghost mx-2" activeClass="active" to="about" smooth={false} duration={500}>
            about
          </Link>
          {process.env.NODE_ENV === "development" ?
           <Link className="button button-ghost mx-2" activeClass="active" to="work" smooth={false} duration={500}>
             work
           </Link> : null}
          <Link className="button button-ghost mx-2" activeClass="active" to="design" smooth={false} duration={500}>
            design
          </Link>
          <a href={pdf}
             target="_blank"
             className="button button-primary mx-2">resumé</a>
        </div>
      </div>
    </div>    
  )
}

export default NavBar;
