import DesignComp from "../images/design-compilation.png"

const Design = () => {
  return (
    <div id="design" className="container d-flex flex-column-reverse flex-lg-row my-5 py-5">
      <div className="flex-4 m-right-40">
        <h1>Design?</h1>
        <h2>...i dabble</h2>
        <p>While I would not go so far as to call myself a designer (others deserve this title more than I do), I have a lot experience with it!  Mockups, wireframes, site layouts, logos and label design are all things I have done which gives me insight into the mind of a designer as well as some of the challenges and obsticles they face.  Having a background in design enables me to bridge the gap between design and development seamlessly. I have a solid understanding of design principles, aesthetics, and user experience, allowing me to effectively communicate with designers and contribute meaningfully to the project planning/building process.</p>
      </div>
      <div className="flex-3">
        <img className="w-100" src={DesignComp}/>
      </div>
    </div>
  )
}

export default Design
