import React, {useState, useRef} from 'react';
import JobModal from "../components/JobModal";

import { experiences, skills } from "../data";
const About = () => {
  const [job, setJob] = useState(null);

  const handleJobClick = (i) => {
    document.getElementById("html").classList.add("locked");
    setJob(i)
  }
  
  const renderExperience = () => {
    return experiences.map((e, i, arr) => {
      const {jobTitle, company, date, skills} = e;
      return (
        <div onClick={() => handleJobClick(i)}>
          <h4 className="text-yellow">{jobTitle}</h4>
          <h5 className="fst-italic">{company}: {date}</h5>
          <div className="w-50 mb-0">
            <p>{skills.join(", ")}</p>
            <button className="button button-secondary dark small my-2">details</button>
          </div>
          {i < arr.length - 1 ? <div className="vert"></div> : null}
        </div>
      )
    })
  }
  const renderSkills = () => {
    return skills.map(s => (
      <div className="skill-box">{s}</div>
    ))
  }

  return (
    <div id="about" className="about-section">
      <div className="container my-5">
        <h1>About Me</h1>
        <div className="d-flex flex-md-row flex-column py-5 px-3 px-lg-0">
          <div className="flex-4">
            <h3 className="mb-4">my story</h3>
            <p className="w-100 pe-5 mb-5">In 2009, I moved to Philadelphia with dreams of a music career. However, limited funds made it impossible to hire someone to build my website. Determined, I learned to code and fell in love with web development. This unexpected transition led me to a rewarding career as a software engineer. Blending my musical background with technical expertise, I bring a unique perspective to software development, creating innovative solutions. Grateful for life's surprises, I embrace the opportunities they bring.</p>
          </div>
          <div className="flex-3">
            <h3 className="mb-4">my skills</h3>
            <div className="d-flex flex-wrap">
              {renderSkills()}
            </div>
          </div>
        </div>
        <div className="px-3 px-lg-0">
          <h3 className="m-top-50 mb-4">experience</h3>
          {renderExperience()}
        </div>
        <JobModal
          job={job}
          callback={setJob}
        />
      </div>
    </div>
  )
}

export default About
