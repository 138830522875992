import React, { useEffect } from "react";

const Contact = () => {
    useEffect(() => {
  const script = document.createElement('script');

  script.src = "//embed.typeform.com/next/embed.js";
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []);
  return (
    <div className="container contact-section py-5 my-5">
      <div className="text-center">
        <h1>Get in touch</h1>
        <p>casey@caseyalvarez.com</p>
        <div className="typeform-wrapper" data-tf-widget="qHq2TlV0" data-tf-opacity="100" data-tf-iframe-props="title=contact" data-tf-transitive-search-params data-tf-medium="snippet"></div>
      </div>
    </div>
  )
}

export default Contact;
