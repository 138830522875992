import Logo from "../images/logo.svg";
import GhLogo from "../images/gh-logo.png";
import LILogo from "../images/linkedin-logo.svg";
const Footer = () => {

  return (
    <div className="footer">
      <img className="footer-logo" src={Logo}/>
      <div className="mt-4 py-5">
        <h2>software engineer</h2>
        <div className="footer-info">
          <span>casey@caseyalvarez.com  |  philadelphia, pa</span>
          <div className="social-logos">
            <img onClick={() => window.location = "https://github.com/CaseyAlvarez"} src={GhLogo}/>
            <img onClick={() => window.location = "https://www.linkedin.com/in/casey-alvarez-28a92123/"} src={LILogo}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
